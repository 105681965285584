import { gql } from '@apollo/client';

const START_OPERATOR = gql`
  mutation startOperator($input: InputMutation) {
    startOperatorResponse(input: $input)
      @rest(
        type: "OperatorRun"
        method: "POST"
        path: "/operator-view/operator-run/start/{args.input.machineId}"
      ) {
      id: operatorRunId
      startAt
      endAt
      companyId
      operator @type(name: "Operator") {
        id
        name
      }
      machine @type(name: "Machine") {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

const STOP_OPERATOR = gql`
  mutation stopOperator($input: InputMutation) {
    stopOperatorResponse(input: $input)
      @rest(
        type: "OperatorRun"
        method: "POST"
        path: "/operator-view/operator-run/stop/{args.input.machineId}"
      ) {
      id: operatorRunId
      startAt
      endAt
      companyId
      operator @type(name: "Operator") {
        id
        name
      }
      machine @type(name: "Machine") {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

const CREATE_OPERATION = gql`
  mutation createOperation($input: InputMutation) {
    createOperationResponse(input: $input)
      @rest(type: "JobPayload", method: "POST", path: "/operator-view/jobs") {
      id
      company_id
      name
      actualParts
      partCountMultiplier
    }
  }
`;

const START_JOB = gql`
  mutation startJob($input: InputMutation) {
    startJobResponse(input: $input)
      @rest(
        type: "Job"
        method: "POST"
        path: "/operator-view/job-dispatch/{args.input.id}/start/{args.input.jobId}"
      ) {
      result
    }
  }
`;

const STOP_JOB = gql`
  mutation stopJob($input: InputMutation) {
    stopJobResponse(input: $input)
      @rest(
        type: "Job"
        method: "POST"
        path: "/operator-view/job-dispatch/{args.input.id}/stop/{args.input.jobId}"
      ) {
      result
    }
  }
`;

const END_SETUP = gql`
  mutation endSetup($input: InputMutation) {
    endSetupResponse(input: $input)
      @rest(
        type: "EndSetup"
        method: "POST"
        path: "/operator-view/job-setup/end-setup/{args.input.jsgId}/{args.input.machineId}"
      ) {
      result
    }
  }
`;

const CREATE_PART_ADJUSTMENT = gql`
  mutation createPartAdjustment($input: InputMutation) {
    createPartAdjustmentResponse(input: $input)
      @rest(
        type: "PartAdjustment"
        method: "POST"
        path: "/operator-view/part-adjustment"
      ) {
      id
      message
      adjustmenttime
      scrapcount
      count
      dataitemname
      scrap
      jobName
      reject_behavior
    }
  }
`;

const CREATE_ANNOTATION = gql`
  mutation createAnnotation($input: InputMutation) {
    createAnnotationResponse(input: $input)
      @rest(
        type: "AnnotationPayload"
        method: "POST"
        path: "/operator-view/annotations"
      ) {
      id
      message
      machineId
      machine_id
      category_id
      annotationTypeId
      duration
      planned
      start
      end
      isEndSet
      setup
      annotationType @type(name: "annotationType") {
        id
        name
        plannedBehavior
        plannedLimitMs
        color
      }
    }
  }
`;

const UPDATE_ANNOTATION = gql`
  mutation updateAnnotation($input: InputMutation) {
    updateAnnotationResponse(input: $input)
      @rest(
        type: "AnnotationPayload"
        method: "PUT"
        path: "/operator-view/annotations/{args.input.id}"
      ) {
      id
      message
      machineId
      machine_id
      category_id
      annotationTypeId
      duration
      planned
      start
      end
      isEndSet
      setup
      annotationType @type(name: "annotationType") {
        id
        name
        plannedBehavior
        plannedLimitMs
        color
      }
    }
  }
`;

const CREATE_ACTIVITY_SET = gql`
  mutation startActivitySet($input: InputMutation) {
    createActivitySetResponse(input: $input, query: $query)
      @rest(
        type: "ActivitySet"
        method: "POST"
        path: "/operator-view/machines/{args.input.machineRef}/activity-sets?shouldCloseLatestSet={args.query.shouldCloseLatestSet}"
      ) {
      activitySet @type(name: "ActivitySet") {
        activitySetRef
        companyId
        machineRef
        closedAt
        updatedAt
        expectedSetupDuration
        idealUnitDuration
        expectedUnitDuration
        optimalUnitDuration
        jobId
        partCountMultiplier
        actualParts
        workOrderId
        workOrderOperationRef
        activities @type(name: "Activity") {
          activityRef
          activitySetRef
          activityTypeRef
          start: startAt
          end: endAt
          updatedAt
          jobScheduleGroupId
        }
      }
    }
  }
`;

const CREATE_ACTIVITY_SET_WITH_WORK_ORDER = gql`
  mutation startActivitySetWithWorkOrder($input: InputMutation) {
    createActivitySetWithWorkOrderResponse(input: $input, query: $query)
      @rest(
        type: "ActivitySet"
        method: "POST"
        path: "/operator-view/machines/{args.input.machineRef}/work-order/activity-sets?shouldCloseLatestSet={args.query.shouldCloseLatestSet}"
      ) {
      activitySet @type(name: "ActivitySet") {
        activitySetRef
        companyId
        machineRef
        closedAt
        updatedAt
        expectedSetupDuration
        idealUnitDuration
        expectedUnitDuration
        optimalUnitDuration
        jobId
        partCountMultiplier
        actualParts
        workOrderId
        workOrderOperationRef
        activities @type(name: "Activity") {
          activityRef
          activitySetRef
          activityTypeRef
          start: startAt
          end: endAt
          updatedAt
          jobScheduleGroupId
        }
      }
    }
  }
`;

const UPDATE_ACTIVITY_SET = gql`
  mutation updateActivitySet(
    $input: InputMutation
    $query: QueryInput = { updateFromWorkOrder: false }
  ) {
    updateActivitySetResponse(input: $input, query: $query)
      @rest(
        type: "ActivitySet"
        method: "PUT"
        path: "/operator-view/machines/{args.input.machineRef}/activity-sets/{args.input.activitySetRef}?updateFromWorkOrder={args.query.updateFromWorkOrder}"
      ) {
      activitySet @type(name: "ActivitySet") {
        activitySetRef
        companyId
        machineRef
        closedAt
        updatedAt
        expectedSetupDuration
        idealUnitDuration
        expectedUnitDuration
        jobId
        partCountMultiplier
        actualParts
        workOrderId
        workOrderOperationRef
        activities @type(name: "Activity") {
          activityRef
          activitySetRef
          activityTypeRef
          start: startAt
          end: endAt
          updatedAt
          jobScheduleGroupId
        }
      }
    }
  }
`;

const CREATE_ACTIVITY = gql`
  mutation createActivity($input: InputMutation) {
    createActivityResponse(input: $input)
      @rest(
        type: "Activity"
        method: "POST"
        path: "/operator-view/activity-sets/{args.input.activitySetRef}/activities"
      ) {
      activity @type(name: "Activity") {
        activityRef
        activitySetRef
        activityTypeRef
        start: startAt
        end: endAt
        jobScheduleGroupId
        name
        updatedAt
      }
    }
  }
`;

const UPDATE_ACTIVITY = gql`
  mutation updateActivity($input: InputMutation) {
    updateActivityResponse(input: $input)
      @rest(
        type: "Activity"
        method: "PUT"
        path: "/operator-view/activity-sets/{args.input.activitySetRef}/activities/{args.input.activityRef}"
      ) {
      activity @type(name: "Activity") {
        activityRef
        activitySetRef
        activityTypeRef
        start: startAt
        end: endAt
        updatedAt
        jobScheduleGroupId
      }
    }
  }
`;

const TRIGGER_WORKFLOW = gql`
  mutation triggerWorkflow($input: InputMutation) {
    triggerWorkflowResponse(input: $input)
      @rest(
        type: "Monitor"
        method: "POST"
        path: "/operator-view/monitors/trigger"
      )
  }
`;

const TIMESYNC = gql`
  mutation timesync($input: InputMutation) {
    timesyncData(input: $input)
      @rest(type: "Timesync", path: "/timesync", method: "POST") {
      result
      id
    }
  }
`;

const CREATE_LABOR_TICKET = gql`
  mutation insertErpLaborTicket($input: erpLaborTickets_insert_input!) {
    insertErpLaborTicket(object: $input) {
      badParts
      clockIn
      clockOut
      goodParts
      laborTicketId
      laborTicketRef
      lot
      personId
      resourceId
      sequenceNumber
      state
      split
      sub
      transactionDate
      type
      workOrderId
      reasons {
        reasonRef
        reason {
          annotationTypeRef
        }
      }
    }
  }
`;

const DELETE_LABOR_TICKET = gql`
  mutation deleteErpLaborTicket($ref: bigint!) {
    deleteErpLaborTicket(laborTicketRef: $ref) {
      laborTicketRef
    }
  }
`;

const UPDATE_LABOR_TICKET = gql`
  mutation updateErpLaborTicket(
    $laborTicketRef: bigint!
    $input: erpLaborTickets_set_input
  ) {
    updateErpLaborTicket(
      pk_columns: { laborTicketRef: $laborTicketRef }
      _set: $input
    ) {
      badParts
      clockIn
      clockOut
      goodParts
      laborTicketId
      laborTicketRef
      lot
      personId
      resourceId
      sequenceNumber
      state
      split
      sub
      transactionDate
      type
      workOrderId
      comment
    }
  }
`;

// workOrderOperation's startQuantity is actually currentQuantity, currently we do not have any insight for the erp _start quantity_.
// Renaming this variable to currentQuantity avoid confusion on the frontend
const UPDATE_ORDER_OPERATION = gql`
  mutation updateErpWorkOrderOperation(
    $workOrderOperationRef: bigint!
    $input: erpWorkOrderOperations_set_input
  ) {
    updateErpWorkOrderOperation(
      pk_columns: { workOrderOperationRef: $workOrderOperationRef }
      _set: $input
    ) {
      closedDate
      finishQuantity
      productionburdenRateHourly
      workOrderOperationRef
      workOrderId
      resourceId
      scheduledFinishDate
      scheduledStartDate
      setupTimeMs
      currentQuantity: startQuantity
      status
      sequenceNumber
      lot
      sub
      split
      cycleTimeMs
      workOrder {
        partNumber
        lot
        workOrderId
        sub
        split
      }
      resource {
        machineRef
        resourceId
        isResourceGroup
      }
    }
  }
`;

const UPDATE_VIA_INSERT_LABOR_TICKET = gql`
  mutation updateViaInsertErpLaborTicket(
    $input: erpLaborTickets_insert_input!
    $columns: [erpLaborTickets_update_column!]
  ) {
    insertErpLaborTicket(
      object: $input
      on_conflict: { constraint: labor_ticket_pk, update_columns: $columns }
    ) {
      comment
      badParts
      clockIn
      clockOut
      goodParts
      laborTicketId
      laborTicketRef
      lot
      personId
      resourceId
      sequenceNumber
      state
      split
      sub
      transactionDate
      type
      workOrderId
      reasons {
        reasonRef
      }
    }
  }
`;

export {
  CREATE_ANNOTATION,
  CREATE_PART_ADJUSTMENT,
  END_SETUP,
  CREATE_OPERATION,
  START_JOB,
  START_OPERATOR,
  STOP_JOB,
  STOP_OPERATOR,
  UPDATE_ANNOTATION,
  CREATE_ACTIVITY_SET,
  UPDATE_ACTIVITY_SET,
  CREATE_ACTIVITY,
  UPDATE_ACTIVITY,
  TRIGGER_WORKFLOW,
  TIMESYNC,
  CREATE_LABOR_TICKET,
  DELETE_LABOR_TICKET,
  UPDATE_LABOR_TICKET,
  CREATE_ACTIVITY_SET_WITH_WORK_ORDER,
  UPDATE_VIA_INSERT_LABOR_TICKET,
  UPDATE_ORDER_OPERATION,
};
