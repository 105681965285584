/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import _get from 'lodash/get';
import Routes from 'lib/utils/routes';
import { useTranslation } from 'react-i18next';
import { toISO } from 'lib/utils/date';
import styled from 'styled-components';
import { palette } from '@m12s/component-library';

import useStartNewProductionRun from 'components/SwitchActivityTypeReview/useStartNewProductionRun';
import useEditLaborTicket from 'components/LaborTicket/hooks/useEditLaborTicket';
import useCreateLaborTicket from 'components/LaborTicket/hooks/useCreateLaborTicket';
import { useIsLaborTicketFlow } from 'lib/hooks/useIsLaborTicketFlow';

import { getIsAPMEnabled } from 'lib/selectors/getIsAPMEnabled';
import { ACTIVITY_MODES } from 'lib/constants';
import { getActivityTypes } from 'lib/selectors/getActivityTypes';
import { getMachine } from 'lib/selectors/getMachine';
import {
  actionSetWorkOrderOpActionLoading,
  actionOptimisticActivitySetUpdate,
  actionCanonicalActivitySetUpdate,
} from 'lib/actions';
import { getPendingActivityTypeRef } from 'lib/selectors/getPendingActivityTypeRef';
import { UPDATE_ACTIVITY_SET } from 'lib/api/mutations';
import { throwErrorToast, throwSuccessToast } from 'lib/utils/toast';
import { getLatestActivitySet } from 'lib/selectors/getLatestActivitySet';
import { getLatestActivitySetRef } from 'lib/selectors/getLatestActivitySetRef';
import { getWorkOrderId } from 'lib/selectors/getWorkOrderId';
import { getCurrentWorkOrderOp } from 'lib/selectors/getCurrentWorkOrderOp';
import { getLatestActivityMode } from 'lib/selectors/getLatestActivityMode';
import { getCurrentLaborTicket } from 'lib/selectors/getLaborTickets';
import { getCurrentOperator } from 'lib/selectors/getCurrentOperator';
import { getLatestOperatorRunStartAt } from 'lib/selectors/getLatestOperatorRunStartAt';
import { getLatestActivityModeStartAt } from 'lib/selectors/getLatestActivityModeStartAt';
import { getHasOpenActivitySet } from 'lib/selectors/getHasOpenActivitySet';

import useCreateActivitySetWithWorkOrder from '../../useCreateActivitySetWithWorkOrder';

import WorkOrderHeader from './WorkOrderHeader';
import WorkOrderBody from './WorkOrderBody';

const WorkOrderCardContainer = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${palette.Grey300};
  background-color: ${palette.White};
  height: 8.875rem;

  width: 100%;
  box-shadow: 0px 1px 1px 0px rgba(26, 32, 44, 0.08),
    0px 1px 3px 1px rgba(26, 32, 44, 0.16),
    0px -1px 4px 0px rgba(26, 32, 44, 0.05);
  opacity: ${({ disabled }) => {
    return disabled ? '0.65' : '1';
  }};
  ${({ disabled }) => {
    return (
      disabled &&
      `
    &:hover {
      cursor: pointer;
    }
  `
    );
  }}
`;

const WorkOrderCard = ({
  workOrderOp,
  nextStep,
  dispatch,
  active = false,
  isBlockedBySequence = false,
}) => {
  const mainDispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const queryParams = queryString.parse(history.location.search);
  const laborTicketEnabled = useIsLaborTicketFlow();
  const createLaborTicket = useCreateLaborTicket();

  const machine = useSelector(getMachine);
  const activityTypes = useSelector(getActivityTypes);
  const latestActivitySet = useSelector(getLatestActivitySet);
  const activitySetRef = useSelector(getLatestActivitySetRef);
  const isAPMEnabled = useSelector(getIsAPMEnabled);
  const latestActivityMode = useSelector(getLatestActivityMode);
  const latestActivityModeStartAt = useSelector(getLatestActivityModeStartAt);
  const currentOperator = useSelector(getCurrentOperator);
  const operatorSignIn = useSelector(getLatestOperatorRunStartAt);
  const hasOpenActivitySet = useSelector(getHasOpenActivitySet);

  const pendingActivityTypeRef = useSelector(getPendingActivityTypeRef);
  const latestWorkOrderId = useSelector(getWorkOrderId);
  const currentWorkOrderOp = useSelector(getCurrentWorkOrderOp);
  const currentLaborTicket = useSelector(getCurrentLaborTicket);
  const isUpdate = !!queryParams.updateWorkOrderId;

  const { workOrder, workOrderOperationRef } = workOrderOp;

  const workOrderId = _get(workOrder, 'workOrderId');
  const setupActivityTypes = activityTypes.filter((type) => {
    return type.activityMode === ACTIVITY_MODES.SETUP;
  });

  const startNewProductionRun = useStartNewProductionRun();
  const editLaborTicket = useEditLaborTicket();
  const requestCreateActivitySetWithWorkOrder = useCreateActivitySetWithWorkOrder(
    {
      machine,
      workOrderOp,
    }
  );

  const [requestUpdateActivitySet] = useMutation(UPDATE_ACTIVITY_SET, {
    fetchPolicy: 'no-cache',
    update: (
      _cache,
      {
        data: {
          updateActivitySetResponse: { activitySet },
        },
      }
    ) => {
      mainDispatch(actionOptimisticActivitySetUpdate({ activitySet }));
    },
    onCompleted: async ({ updateActivitySetResponse: { activitySet } }) => {
      await mainDispatch(actionCanonicalActivitySetUpdate({ activitySet }));
      await mainDispatch(actionSetWorkOrderOpActionLoading(false));
      throwSuccessToast(`Order updated to ${activitySet.workOrderId}`);

      return history.push({
        pathname: Routes.machineIdHomePath(machine.id),
      });
    },
    onError: () => {
      mainDispatch(
        actionCanonicalActivitySetUpdate({
          activitySet: {
            ...latestActivitySet,
            workOrderId: latestWorkOrderId,
          },
        })
      );
      mainDispatch(actionSetWorkOrderOpActionLoading(false));
      throwErrorToast(t('Failed to update production order'));
    },
  });

  const handleStartAndUpdateManual = () => {
    const partOperation = workOrderOp.matchingPartOperations
      ? workOrderOp.matchingPartOperations[0]
      : {
          // if no matchingPartOperations, it means its coming from the produciton schedule endpoint, values are included directly on woop
          ...workOrderOp,
        };

    if (partOperation?.partOperationRef) {
      if (!isAPMEnabled && isUpdate) {
        mainDispatch(actionSetWorkOrderOpActionLoading(true));
        return requestUpdateActivitySet({
          variables: {
            input: {
              machineRef: machine?.machineRef,
              activitySetRef,
              workOrderId,
              workOrderOperationRef,
              partNumber: partOperation.partNumber,
              sequenceNumber: partOperation.sequenceNumber,
              partOperationRef: partOperation.partOperationRef,
            },
            query: { updateFromWorkOrder: 'true' },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateActivitySetResponse: {
              __typename: 'ActivitySet',
              activitySet: {
                ...latestActivitySet,
                workOrderId,
                workOrderOperationRef,
              },
            },
          },
        });
      }
      if (pendingActivityTypeRef) {
        return requestCreateActivitySetWithWorkOrder({
          activityTypeRef: pendingActivityTypeRef,
          partOperation,
        });
      }

      if (setupActivityTypes.length === 1) {
        return requestCreateActivitySetWithWorkOrder({
          activityTypeRef: setupActivityTypes[0].activityTypeRef,
          partOperation,
        });
      }

      dispatch({
        type: 'SET_PART_OPERATION',
        partOperation,
      });
      return nextStep();
    }
    mainDispatch(actionSetWorkOrderOpActionLoading(false));
    return throwErrorToast('Unable to find part operation');
  };

  const disabled = active || (isUpdate && !latestActivitySet);

  return (
    <WorkOrderCardContainer
      key={workOrderOperationRef}
      disabled={disabled}
      onClick={() => {
        if (disabled) {
          return;
        }

        if (isBlockedBySequence) {
          return throwErrorToast(
            'Schedule conflict: Operations must follow sequence on the same machine'
          );
        }

        dispatch({
          type: 'SET_WORK_ORDER_OPERATION',
          workOrderOp,
        });
        if (queryParams.activityTypeRef) {
          // PRODUCTION → SETUP WITH CREATE NEW PRODUCTION RUN
          // + STOPPING AND STARTING NEW ORDER FOR APM
          return startNewProductionRun(
            queryParams.activityTypeRef,
            workOrder.workOrderId,
            workOrderOperationRef
          );
        }
        if (isUpdate) {
          // EDIT / ADD ORDER OPERATION
          mainDispatch(actionSetWorkOrderOpActionLoading(true));
          if (laborTicketEnabled) {
            if (currentLaborTicket) {
              editLaborTicket(
                {
                  lot: workOrderOp.lot,
                  sequenceNumber: workOrderOp.sequenceNumber,
                  split: workOrderOp.split,
                  sub: workOrderOp.sub,
                  workOrderId: workOrderOp.workOrderId,
                },
                currentLaborTicket.laborTicketRef
              );
            } else {
              const clockIn = toISO(
                Math.max(
                  moment(operatorSignIn).valueOf(),
                  latestActivityModeStartAt
                    ? moment(latestActivityModeStartAt).valueOf()
                    : 0
                )
              );

              createLaborTicket({
                clockIn,
                clockOut: null,
                lot: workOrderOp.lot,
                sequenceNumber: workOrderOp.sequenceNumber,
                split: workOrderOp.split,
                sub: workOrderOp.sub,
                state: 'OPEN',
                resourceId: machine.erpResourceId,
                personId: currentOperator.erpId,
                workOrderId: workOrderOp.workOrderId,
                type: latestActivityMode,
                goodParts: 0,
                badParts: 0,
              });
            }
          }

          if (isAPMEnabled) {
            // ONLY UPDATE FOR ID/REF FOR APM
            return requestUpdateActivitySet({
              variables: {
                input: {
                  machineRef: machine?.machineRef,
                  activitySetRef,
                  workOrderId,
                  workOrderOperationRef,
                },
              },
              optimisticResponse: {
                __typename: 'Mutation',
                updateActivitySetResponse: {
                  __typename: 'ActivitySet',
                  activitySet: {
                    ...latestActivitySet,
                    workOrderId,
                    workOrderOperationRef,
                  },
                },
              },
            });
          }
        }

        if (!hasOpenActivitySet && currentWorkOrderOp) {
          return nextStep();
        }

        if (setupActivityTypes.length === 1 || pendingActivityTypeRef) {
          mainDispatch(actionSetWorkOrderOpActionLoading(true));
        }
        // MANUAL MACHINE - START / UPDATE BASED ON ORDER OPERATION
        return handleStartAndUpdateManual();
      }}
    >
      <WorkOrderHeader workOrderOp={workOrderOp} active={active} />
      <WorkOrderBody workOrderOp={workOrderOp} active={active} />
    </WorkOrderCardContainer>
  );
};

WorkOrderCard.propTypes = {
  workOrderOp: PropTypes.object,
  nextStep: PropTypes.func,
  dispatch: PropTypes.func,
  active: PropTypes.bool,
  isBlockedBySequence: PropTypes.bool,
};

export default WorkOrderCard;
