import { createSelector } from 'reselect';
import { getOperators } from 'lib/selectors/getOperators';

const defaultActiveOperators = [];

const getActiveOperators = createSelector([getOperators], (operators) => {
  if (operators?.length > 0) {
    return operators?.filter((operator) => {
      return !operator.archivedAt;
    });
  }
  return defaultActiveOperators;
});

export { getActiveOperators };
