import { createSelector } from 'reselect';
import _get from 'lodash/get';

import { getIsAPMEnabled } from './getIsAPMEnabled';
import { getLatestActivitySet } from './getLatestActivitySet';

import { getCurrentWorkOrderOp } from './getCurrentWorkOrderOp';

import { getCurrentOperator } from './getCurrentOperator';
import { getCurrentLaborTicket } from './getLaborTickets';

const getCanOpenLaborTicket = createSelector(
  [
    getIsAPMEnabled,
    getCurrentWorkOrderOp,
    getLatestActivitySet,
    getCurrentOperator,
  ],
  (isAPMEnabled, currentWorkOrderOp, latestActivitySet, currentOperator) => {
    return !!(
      !isAPMEnabled &&
      currentWorkOrderOp &&
      _get(latestActivitySet, 'closedAt') === null &&
      _get(currentOperator, 'erpId')
    );
  }
);

const getCanCloseLaborTicket = createSelector(
  [
    getIsAPMEnabled,
    getCurrentOperator,
    getCurrentLaborTicket,
    getCurrentWorkOrderOp,
  ],
  (isAPMEnabled, currentOperator, currentLaborTicket, currentWorkOrderOp) => {
    return !!(
      !isAPMEnabled &&
      _get(currentOperator, 'erpId') &&
      currentLaborTicket &&
      currentWorkOrderOp
    );
  }
);

export { getCanOpenLaborTicket, getCanCloseLaborTicket };
